.top {
  margin-right: 50px;
  margin-left: 50px;
  width: 100%;
  height: 100%;
}

.config {
  height: 50px;
  width: 50px;
  border: 0 none;
  font-size: 26px;
  background-color: transparent;
  outline: 0 none;
}

.config:hover {
  transform: scale(0.8);
  cursor: pointer;
}

.config:active {
  transform: scale(0.8);
}

.searchBar {
  margin-top: 35px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  border: 0;
}

.searchBar > form > button {
  cursor: pointer;
  margin-left: 15px;
  background-color: transparent;
  border: 0 none;
  outline: 0;
  font-size: 30px;
}

.searchBar > form > button:active {
  transform: scale(0.9);
}

.searchBar > form > input {
  justify-content: center;
  align-items: center;
}

.inputSearchBar {
  margin-left: 25px;
  margin-top: 5px;
  width: 90%;
  height: 40px;
  background-color: transparent;
  border: 0 none;
  outline: 0;
  color: black;
  font-size: 25px;
}

.buttons {
  margin-top: 15px;
}

.refreshBtn {
  width: 100px;
  height: 50px;
  background: #ffc720;
  border: 1px solid #ffca2c;
  border-radius: 100px;
  cursor: pointer;
}

.refreshBtn > i {
  color: white;
  font-size: 30px;
}

.refreshBtn:hover {
  cursor: pointer;
  background-color: #fdd65e;
}

.refreshBtn:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(255, 208, 0, 0.24);
}

.addBtn {
  width: 100px;
  height: 50px;
  background: #198754;
  border: 1px solid #198754;
  border-radius: 100px;
  cursor: pointer;
  margin-left: 10px;
}

.addBtn > i {
  color: white;
  font-size: 30px;
}

.addBtn:hover {
  cursor: pointer;
  background-color: #2f9465;
}

.addBtn:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(43, 255, 0, 0.24);
}

.order {
  margin-top: 35px;
  background-color: #ffffff;
  width: 100%;
}
.celulas {
  width: 15%;
  font-size: 15px;
  border: 1px solid #000000;
}

.celulas_name {
  width: 20%;
  font-size: 15px;
  border: 1px solid #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
}

.status {
  width: 110px;
  height: 40px;
  border-radius: 20px;
  margin: auto;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  border: 0 none;
  font-weight: bolder;
}

.celulas1 {
  width: 15%;
  background-color: #252625;
  border-radius: 5px;
  color: white;
}

.order > table > thead > tr > th {
  border: 1px solid #000000;
  height: 40px;
  border-radius: 5px;
}

.order > table > tbody > tr > th {
  border: 1px solid #000000;
  height: 40px;
}

.order > table > tbody > tr > td {
  border: 1px solid #000000;
  height: 95px;
  text-align: center;
}

.buttontable {
  background-color: transparent;
  border: 0 none;
  font-size: 18px;
  height: 100%;
  width: 100%;
  outline: 0;
}

.buttontable:hover {
  transform: scale(0.8);
  cursor: pointer;
}

.buttontable:active {
  transform: scale(0.8);
}

@media only screen and (max-width: 968px) {
  .inputSearchBar {
    width: 78%;
  }

  .top {
    margin-right: 10px;
    margin-left: 10px;
  }

  .addBtn {
    width: 70px;
    height: 35px;
  }

  .addBtn > i {
    font-size: 25px;
  }

  .refreshBtn {
    width: 70px;
    height: 35px;
  }

  .refreshBtn > i {
    font-size: 25px;
  }

  .status {
    width: 84px;
    height: 30px;
    border-radius: 20px;
    margin: auto;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    border: none 0;
    font-weight: bolder;
  }
}
