#sidebar {
  background: #252625;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5;
  background-color: #252625;
  box-shadow: 0px 4px 4px #000000;
  text-align: center;
}

.sidebar__tittle {
  display: block;
  justify-content: space-between;
  color: #f3f4f6;
  margin-bottom: 1.25rem;
  margin-top: 0.9375rem;
  text-align: end;
  padding-right: 0.9375rem;
}

.sidebar_img {
  margin: auto;
  display: block;
  text-align: center;
}

.sidebar__avatar {
  margin-top: 3.4375rem;
  margin-bottom: 0.675rem;
}

.sidebar__avatar > div > img {
  width: 15.4375rem;
  height: 15.4375rem;
  border-radius: 50%;
}

.sidebar__avatar > div > button {
  width: 15.4375rem;
  height: 15.4375rem;
  border-radius: 50%;
  background-color: transparent;
}

.sidebar__user > ul > h1 {
  font-size: 2rem;
  display: inline;
  margin-bottom: 0.9375rem;
  color: #f3f4f6;
}

.sidebar__tittle > i {
  font-size: 1.125rem;
  display: none;
  position: absolute;
}

.sidebar__menu {
  color: #494949;
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 0.3125rem;
  padding: 0;
  font-weight: 800;
}

.sidebar__button {
  text-decoration: none;
  font-size: 2rem;
  width: 100%;
  height: 4.4375rem;
  border: 0;
  background: #252625;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border-radius: 0.625rem;
}

.sidebar__button > a {
  text-decoration: none;
  color: #f3f4f6;
}

.sidebar__button > i {
  text-decoration: none;
  color: #f3f4f6;
  margin-right: 0.9375rem;
}

.sidebar__button:hover {
  cursor: pointer;
  background-color: #494949;
  color: white;
}

.sidebar__button:active {
  transform: scale(0.9);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.sidebar__buttonf {
  text-decoration: none;
  font-size: 2rem;
  width: 100%;
  height: 4.4375rem;
  border: 0;
  background: #252625;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.sidebar__buttonf > a {
  text-decoration: none;
  color: #f3f4f6;
}

.sidebar__buttonf > i {
  text-decoration: none;
  color: #f3f4f6;
  margin-right: 0.9375rem;
}

.sidebar__buttonf:hover {
  cursor: pointer;
  background-color: #b9000052;
  color: red;
}

.sidebar__buttonf:active {
  transform: scale(0.9);
  background-color: #b9000052;
  box-shadow: 3px 2px 22px 1px rgba(255, 0, 0, 0.212);
}

.sidebar-responsive {
  display: inline !important;
  z-index: 99999 !important;
  left: 0 !important;
  position: absolute;
  height: 100vh;
  font-size: 1.25rem;
  width: 6.25rem;
}

@media only screen and (max-width: 1000px) {
  #sidebar {
    display: none;
  }
}
