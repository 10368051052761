.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* estilizacao scroll bar */

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: insert 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

/* estilizacoes gerais */

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.cotainerHomePage {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.3fr repeat(2, 2fr);
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar nav nav '
    'sidebar main main';
}

@media only screen and (max-width: 1366px) {
  .cotainerHomePage {
    display: grid;
    height: 100vh;
    grid-template-columns: 0.2fr repeat(2, 2fr);
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar nav nav '
      'sidebar main main';
  }
}

@media only screen and (max-width: 1000px) {
  .cotainerHomePage {
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr;
    grid-template-areas: 'nav' 'main';
  }
}
