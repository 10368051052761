.dataResult {
    border-radius: 5px;
    background: #f1f1f6;
    width: 74%;
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    margin: 0 auto;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataItem {
    padding: 10px 32px;
    display: flex;
    align-items: center;
    gap: 26px;
    cursor: pointer;
    border: 1px point black;
}

.dataItem:hover {
    background-color: #e9e9e9;
}


@media only screen and (max-width: 968px) {
    .dataResult {
        max-height: 300px;
    }
}