.table__head {
    border: 1px solid #000000;
    width: 8%;
    text-align: center;
    background-color: white;
}

.table__cabecalho {
    background-color: #252625;
    color: white;
    border: 1px solid #000000;
    width: 8%;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
}

.table__heade {
    width: 6%;
    background-color: white;
    text-align: center;
    border: 1px solid #000000;
    height: 50px;
    align-items: center;
}

.table__heade>a>i {
    margin: 10px;
    text-decoration: none;
    color: black;
    text-decoration: none;
    font-size: 25px;
}

.table__heade>a>button>i {
    margin: 10px;
    text-decoration: none;
}

.table__heade>button {
    margin: 10px;
    text-decoration: none;
}


.table__cabecalhoCheck {
    width: 5%;
    background-color: #252625;
    color: white;
    text-align: center;
    height: 50px;
    border-radius: 5px;
}

.orderitem__buton {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.orderitem__buton>a>button {
    width: 200px;
    height: 60px;
    border: 0 none;
    color: #FFFFFF;
    font-size: 20px;
    border-radius: 30px;
    text-decoration: none;
    margin-bottom: 15px;
    outline: 0;
}

.orderitem__buton>a>button>i {
    margin-right: 10px;
}

.orderitem__buton>button>a>i {
    margin-right: 10px;
}

.button__orderdat {
    background: #252625;
    border: 1px solid #252625;
    color: white;
    text-decoration: none;
}

.button__orderdat:hover {
    cursor: pointer;
    background-color: #555555;
}

.button__orderdat:active {
    transform: scale(0.80);
    box-shadow: 1px 1px 1px 1px rgba(41, 41, 41, 0.24);
}


@media only screen and (max-width: 968px) {
    .table__head {
        font-size: 12px;
    }
}