.gerar_pdf {
    background: #198754;
    border: 1px solid #198754;
    width: 100px;
    margin-left: 43px;
    color: white;
    border-radius: 15px;
    font-weight: bold;
    font-size: 12px;
}

.gerar_pdf:active {
    transform: scale(0.90);
}

.gerar_pdf:hover {
    transform: scale(0.90);
}

.gerar_pdf_voltar {
    background: #252625;
    border: 1px solid #252625;
    width: 100px;
    margin-left: 43px;
    color: white;
    border-radius: 15px;
    font-weight: bold;
    font-size: 12px;
}

.gerar_pdf_voltar:active {
    transform: scale(0.90);
}

.gerar_pdf_voltar:hover {
    transform: scale(0.90);
}

.pdfmain {
    width: 100%;
    height: 100vh;
}