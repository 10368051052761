.newItemMain {
  padding: 20px;
  width: 100%;
  margin-top: 15px;
}

.newItemMain > h2 {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.newItemHead {
  border: 1px solid #000000;
  background-color: white;
  display: flex;
  border-radius: 10px;
}

.letterStroke {
  /* largura da borda */
  -webkit-text-stroke-width: 0.7px;
  /* cor da borda */
  -webkit-text-stroke-color: #000;
}

.newItemHead > div > i {
  /* largura da borda */
  -webkit-text-stroke-width: 1.5px;
  /* cor da borda */
  -webkit-text-stroke-color: #000;
}

.newItemHead__strings {
  margin: 5px;
}

.newItemHead__strings > h4 {
  margin: 5px;
}

.newItemHead__strings > p {
  margin: 5px;
}

.newItemHead > div > i {
  margin: 25px;
  font-size: 60px;
}

.baias {
  margin-top: 10px;
  border: 1px solid #000000;
  background-color: white;
  font-size: 15px;
  color: black;
  font-weight: bold;
  border-radius: 10px;
}

.baiasteste {
  margin: 15px;
}

.baias > input {
  border: 0 none;
  height: 50px;
  outline: 0;
  width: 80%;
  background-color: transparent;
  margin-left: 15px;
}

.entregarate {
  margin: 15px;
}

.naoentregar {
  margin-top: 15px;
  margin-bottom: 15px;
}

.naoentregar > input {
  margin-right: 15px;
}

.entregarate > label {
  margin-right: 15px;
}

.entregarate > textarea {
  margin-top: 5px;
  width: 100%;
  resize: none;
}

.orderitem__butons {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.orderitem__butons > button {
  width: 200px;
  height: 60px;
  border: 0 none;
  color: #ffffff;
  font-size: 20px;
  border-radius: 30px;
  text-decoration: none;
  margin-bottom: 15px;
  outline: 0;
}

.orderitem__butons > button > i {
  margin-right: 10px;
}

.orderitem__butons > button > a > i {
  margin-right: 10px;
}

.button__addorder {
  background: #198754;
  border: 1px solid #198754;
}

.button__addorder:hover {
  cursor: pointer;
  background-color: #2f9465;
}

.button__addorder:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(43, 255, 0, 0.24);
}

.button__orderdata {
  background: #252625;
  border: 1px solid #252625;
  color: white;
  text-decoration: none;
}

.button__orderdata2 {
  background: #252625;
  border: 1px solid #252625;
  color: white;
  text-decoration: none;
}

.button__orderdata:hover {
  cursor: pointer;
  background-color: #555555;
}

.button__orderdata:active {
  transform: scale(0.8);
  box-shadow: 1px 1px 1px 1px rgba(41, 41, 41, 0.24);
}

.calendar__button {
  width: 150px;
  height: 30px;
  border: 0 none;
  color: #ffffff;
  font-size: 16px;
  border-radius: 10px;
  text-decoration: none;
  margin-bottom: 15px;
  outline: 0;
  background: #252625;
}

.calendar__button:hover {
  cursor: pointer;
  background-color: #555555;
}

.calendar__button:active {
  transform: scale(0.8);
  box-shadow: 1px 1px 1px 1px rgba(41, 41, 41, 0.24);
}

@media only screen and (max-width: 968px) {
  .orderitem__butons > button {
    width: 150px;
    height: 40px;
    font-size: 18px;
    border-radius: 30px;
    text-decoration: none;
  }

  .calendar__button {
    width: 120px;
    height: 30px;
    font-size: 14px;
  }
}
