.orderadd__main {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.orderadd__client {
  width: 100%;
  height: 80px;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
}

.orderadd__tittle {
  /* margin-left: 20px; */
  display: block;
  margin-bottom: 5px;
  margin-top: 15px;
}

.orderadd__tittle > i {
  margin-right: 10px;
}

.orderadd__tittle > input {
  width: 95%;
  margin-top: 15px;
  height: 30px;
  margin-right: 20px;
  border: 0 none;
  background-color: transparent;
  outline: 0;
}

.orderadd__tittle > a > i {
  text-decoration: none;
  font-size: 20px;
  color: black;
}

.orderadd__select {
  margin-left: 15px;
  margin-right: 15px;
}

.orderadd__select > select {
  font-size: 18px;
  width: 100%;
  border: none;
  outline: 0;
  height: 45px;
  background-color: white;
  font-size: 16px;
}

.orderadd__menuselect {
  margin-top: 15px;
  background-color: white;
}

.orderadd__menu {
  border-radius: 10px;
  margin-bottom: 5px;
  width: 100%;
  height: 70px;
  border: 1px solid #000000;
  display: flex;
  justify-content: space-between;
}

.orderadd__men {
  border-radius: 10px;
  margin-bottom: 5px;
  width: 100%;
  height: 80px;
  border: 1px solid #000000;
  display: flex;
  justify-content: space-between;
}

.orderadd__men > div {
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  text-decoration: 0;
  color: black;
  width: 100%;
  margin-right: 15px;
  background-color: transparent;
  border: 0 none;
  outline: 0;
}

.orderadd__men > div > label {
  margin-left: 5px;
}

.orderadd__men > div > div > select {
  resize: none;
  width: 99%;
  border: 0 none;
  outline: 0;
  margin-right: 30px;
  margin-top: 10px;
  font-size: 16px;
}

.orderadd__menu > input {
  width: 51%;
  margin-right: 15px;
  background-color: transparent;
  outline: 0;
  padding-left: 10px;
  border: 0 none;
}

.orderadd__menu > .pedrep {
  width: 80%;
  margin-right: 15px;
  background-color: transparent;
  outline: 0;
  border: 0 none;
}

.orderadd__menu > a > button {
  margin-right: 15px;
  height: 50px;
  width: 50px;
  font-size: 20px;
  border: 0 none;
  outline: 0;
  background-color: transparent;
  color: black;
}

.Buttons {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.Buttons > .buttonCopy > i {
  margin-right: 10px;
}

.Buttons > a > .buttonItens > i {
  margin-left: 10px;
}

.Buttons > a > button {
  width: 200px;
  height: 60px;
  border: 0 none;
  color: #ffffff;
  font-size: 20px;
  border-radius: 30px;
}

.Buttons > button {
  width: 200px;
  height: 60px;
  border: 0 none;
  color: #ffffff;
  font-size: 20px;
  border-radius: 30px;
}

.buttonItens {
  background-color: #252625;
}

.buttonCopy {
  background-color: #198754;
}

.buttonCopy:hover {
  cursor: pointer;
  background-color: #2f9465;
}

.buttonCopy:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(43, 255, 0, 0.24);
}

.buttonItens:hover {
  cursor: pointer;
  background-color: #555555;
}

.buttonItens:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(41, 41, 41, 0.24);
}

.buttonRetornar {
  background-color: #252625;
}

.buttonRetornar > i {
  margin-right: 15px;
}

.buttonRetornar:hover {
  cursor: pointer;
  background-color: #555555;
}

.buttonRetornar:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(41, 41, 41, 0.24);
}

.orderadd__menu > .pedrep {
  border: 0px solid #000000;
  border-bottom: 1px solid #000000;
  margin: auto;
  height: 30px;
  margin-right: 35px;
}

.orderadd__menu > span {
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  margin-top: 15px;
  font-size: 18px;
  text-decoration: 0;
  color: black;
}

.orderadd__menu > textarea {
  resize: none;
  width: 70%;
  height: 60px;
  vertical-align: middle;
  outline: 0;
  margin-top: 4px;
  margin-right: 35px;
  border: 1px solid #0000001a;
  border-radius: 5px;
}

.orderadd__menu > select {
  resize: none;
  width: 70%;
  height: 40px;
  border: 0 none;
  outline: 0;
  margin-top: 15px;
  margin-right: 30px;
}

@media only screen and (max-width: 968px) {
  .orderadd__main {
    margin-right: 5px;
    margin-left: 5px;
  }

  .orderadd__client {
    margin-top: 5px;
  }

  .Buttons > a > button {
    width: 160px;
    height: 40px;
    font-size: 15px;
  }

  .Buttons > a > .buttonCopy > i {
    margin-right: 5px;
  }

  .Buttons > a > .buttonItens > i {
    margin-left: 5px;
  }

  .orderadd__menu > textarea {
    width: 60%;
  }

  .orderadd__menu > input {
    resize: none;
    width: 70%;
    margin-right: 0;
  }

  .orderadd__menu > span {
    margin-left: 5px;
    margin-right: 5px;
  }

  .orderadd__tittle > input {
    width: 89%;
  }

  .orderadd__menu {
    height: 70px;
  }

  .Buttons > button {
    width: 160px;
    height: 40px;
    font-size: 15px;
  }
}
