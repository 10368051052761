.buttonPosition {
  position: fixed;
  bottom: 20px;
  right: 68px;
  background-color: #252625;
  color: white;
  border: 0 none;
  font-size: 18px;
  text-align: center;
  width: 112px;
  height: 42px;
  border-radius: 30px;
  outline: none;
}

.buttonPosition > i {
  margin-top: 1px;
}

.buttonPosition:active {
  transform: scale(0.9);
}

.buttonPosition:hover {
  cursor: pointer;
  transform: scale(0.9);
}

.buttonPostionDiv {
  text-align: right;
}

@media only screen and (max-width: 968px) {
  .buttonPosition {
    bottom: 20px;
    right: 24px;
    font-size: 14px;
    width: 84px;
    height: 34px;
  }

  .buttonPosition > p {
    font-size: 12px;
    font-weight: bolder;
    margin-bottom: 10px;
  }
}
