.table__thead {
  color: white;
  width: 8%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #252625;
  border-collapse: collapse;
}

.table__theadBackground {
  background-color: #252625;
}

.table__theadRepresentate {
  width: 30%;
  color: white;
  text-align: start;
  border-radius: 5px;
  height: 50px;
}

.table__theadRepresentate > span {
  margin-left: 20px;
}

.table__tbodyRepresentante {
  background-color: white;
  height: 50px;
  text-align: start;
  border-top: 2px solid black;
}

.table__tbody {
  background-color: white;
  border-top: 2px solid black;
}

.tbodyy {
  border: 1px solid black;
}

.table__tbodyRepresentante > a > span {
  margin-left: 15px;
}

.table__tbodyRepresentante > a > span > i {
  margin-right: 15px;
}

.spanGrafico {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
  color: black;
}

.selectDiretoria {
  border: 0 none;
  outline: 0 none;
  margin-top: 17px;
  width: 100%;
}

.chartDonuts {
  background-color: white;
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
}

.meta {
  font-size: 14px;
  font-weight: bold;
  color: #adadad;
}

.grafico {
  background-color: white;
}

.divchart {
  height: 330px;
  width: 330px;
  background-color: white;
  border: 0 none;
}

.chartBar {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: white;
  font-weight: bolder;
}

.loading {
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.table__theadRepresentateVendas {
  width: 70%;
  height: 60px;
  color: white;
  text-align: start;
  border-radius: 5px;
}

.table__theadRepresentateVendas > span {
  margin-left: 15px;
}

@media only screen and (max-width: 1600px) {
  .containerMain {
    background-size: 200px;
  }

  h2 {
    font-size: 20px;
  }

  .spanGrafico {
    font-size: 12px;
  }

  .meta {
    font-size: 12px !important;
  }

  .table__tbody {
    font-size: 12px;
  }

  .table__theadRepresentate {
    font-size: 14px !important;
  }

  .table__thead {
    font-size: 14px;
  }

  .table__theadRepresentateVendas {
    font-size: 12px;
  }
}
