.orderitem__butons2 {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.orderitem__butons1 {
  margin-top: 15px;
}

.mainitempedidos {
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 20px;
}

.mainitempedidostable {
  margin-top: 35px;
}

.table__head > a > i:active {
  transform: scale(0.8);
  color: red;
}

.table__heade > a > i:hover {
  transform: scale(0.8);
  color: red;
}

.table__head > a > i:hover {
  transform: scale(0.8);
  color: red;
}

.table__head > a {
  text-decoration: none;
  color: black;
  font-size: 24px;
}

.button__addorder1 {
  background: #198754;
  border: 1px solid #198754;
}

.orderitem__butons1 > a > button {
  width: 180px;
  height: 50px;
  border: 0 none;
  color: #ffffff;
  font-size: 20px;
  border-radius: 30px;
  text-decoration: none;
}

.button__addorder1:hover {
  cursor: pointer;
  background-color: #2f9465;
}

.button__addorder1:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(43, 255, 0, 0.24);
}

.button__orderdata1 {
  background: #252625;
  border: 1px solid #252625;
  color: white;
  text-decoration: none;
}

.button__orderdata1:hover {
  cursor: pointer;
  background-color: #555555;
}

.button__orderdata1:active {
  transform: scale(0.8);
  box-shadow: 1px 1px 1px 1px rgba(41, 41, 41, 0.24);
}

.buttonalert__confirm {
  width: 120px;
  height: 35px;
  border: 0 none;
  color: #ffffff;
  font-size: 16px;
  border-radius: 30px;
  text-decoration: none;
  background-color: #252625;
  margin-right: 15px;
  font-weight: 800px;
  text-align: center;
}

.returnPage {
  position: 'fixed';
  background: '#333';
  color: 'white';
}

.buttonalert__confirm > i {
  margin-left: 5px;
  font-size: 18px;
}

.buttonalert__confirm > span {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700px;
}

.buttonalert__confirm:hover {
  cursor: pointer;
  background-color: green;
}

.buttonalert__confirm:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(14, 87, 0, 0.24);
  background-color: green;
}

.buttonalert__cancel {
  width: 120px;
  height: 35px;
  border: 0 none;
  color: #ffffff;
  font-size: 16px;
  border-radius: 30px;
  text-decoration: none;
  background-color: #252625;
  margin-right: 15px;
  font-weight: 800px;
  text-align: center;
}

.buttonalert__cancel > i {
  margin-left: 5px;
  font-size: 18px;
}

.buttonalert__cancel > span {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700px;
}

.buttonalert__cancel:hover {
  cursor: pointer;
  background-color: red;
}

.buttonalert__cancel:active {
  transform: scale(0.9);
  box-shadow: 1px 1px 1px 1px rgba(255, 0, 0, 0.507);
  background-color: red;
}

@media only screen and (max-width: 968px) {
  .orderitem__butons1 > a > button {
    width: 150px;
    height: 40px;
    font-size: 18px;
    border-radius: 30px;
    text-decoration: none;
  }
}
