.mainsearch {
  text-align: center;
  width: 100%;
  padding: ;
  display: inline-block;
}

.mainsearch > h3 {
  margin-bottom: 15px;
}

.mainsearch > h3 > a > button {
  background-color: transparent;
  border: 0 none;
  outline: 0;
  font-size: 20px;
  margin-left: 15px;
  color: black;
}

.mainsearch > h2 {
  margin-bottom: 15px;
}

.mainsearch__search {
  width: 100%;
  margin-bottom: 20px;
}

.mainsearch__search > div > input {
  width: 70%;
  outline: 0;
  height: 30px;
  border-radius: 8px;
  margin-right: 10px;
}

.mainsearch__search > div > button {
  background-color: transparent;
  border: 0 none;
  outline: 0;
  font-size: 20px;
  margin-right: 15px;
}

.mainsearch__search > div > a > button {
  background-color: transparent;
  border: 0 none;
  outline: 0;
  font-size: 20px;
  margin-right: 15px;
}

.main__main {
  background-color: white;
  display: flex;
  width: 100%;
}

.table__justify {
  width: 100%;
  justify-content: space-between;
}

.main__main > table > thead > tr > th {
  border: 1px solid #000000;
}

.main__main > td {
  border: 1px solid #000000;
}

.clicksearch:hover {
  cursor: pointer;
  transform: scale(0.8);
}

.clicksearch:hover {
  transform: scale(0.8);
}

.clicksearcht {
  background-color: transparent;
  border: 0 none;
  outline: 0;
  margin: 0;
  font-size: 18px;
}

.clicksearcht:hover {
  cursor: pointer;
  transform: scale(0.8);
}

.clicksearcht:hover {
  transform: scale(0.8);
}

.teste__teste__teste {
  -webkit-display: flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.table-responsive {
  width: 100% !important;
}

.table {
  /* border: 1px solid black;
  width: 50% !important;
  /*Importante manter o !important rs */
  /* margin: auto;
  background-color: white;  */
}

.clicksearche > a > button {
  background-color: transparent;
}
