.buscatipopedido > td > a > button {
  border: 0 none;
  outline: 0;
  background-color: transparent;
  font-size: 25px;
  margin-left: 35px;
}

.buscatipopedido {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buscatipopedido > td {
  height: 50px;
}

.formcolor {
  width: 30px;
  height: 30px;
  border: 0.1px solid #000000;
  margin: auto;
  border-radius: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.textoInformativo {
  color: red;
  font-weight: bolder;
  margin-bottom: 2px;
}

.carregamento {
  color: red;
  font-weight: bolder;
  margin-top: 10px;
}

.checkcomprado {
  color: green;
  font-size: 30px;
  margin-left: 10px;
}

.divbtn__carregarmais {
  margin: 15px;
  text-align: end;
}

.btn__carregarmais {
  width: 180px;
  height: 50px;
  border: 0 none;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  border-radius: 30px;
  text-decoration: none;
  background: #252625;
  border: 1px solid #252625;
}

.btn__carregarmais:hover {
  cursor: pointer;
  background-color: #555555;
}

.btn__carregarmais:active {
  transform: scale(0.8);
  box-shadow: 1px 1px 1px 1px rgba(41, 41, 41, 0.24);
}

.btn__carregarmais > i {
  font-size: 18px;
}

@media only screen and (max-width: 1100px) {
  .formcolor {
    width: 20px;
    height: 20px;
    margin: 5px;
  }

  .buscatipopedido > td > a > button {
    border: 0 none;
    outline: 0;
    background-color: transparent;
    font-size: 35px;
    margin-left: 35px;
  }

  .buscatipopedido {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .buscatipopedido > td {
    height: 50px;
  }

  .checkcomprado {
    font-size: 20px;
    margin-left: 2px;
  }

  .btn__carregarmais {
    width: 100px;
    height: 30px;
    font-size: 16px;
    border-radius: 30px;
    text-decoration: none;
  }

  .btn__carregarmais > i {
    font-size: 12px;
  }
}
